import '../Scss/style.scss';
import {defineAsyncComponent, inject, ref, watch} from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import {Component} from '@vue/runtime-core';
import LazyLoad from 'vanilla-lazyload';
import createApp, {VueDsCookies, DsPhotoSwipe} from '@digitalwerk/frontend-muster';
import PrimeVue from 'primevue/config';
import {ElSelect, ElOption, ElDatePicker} from 'element-plus';
import {Form, Field, ErrorMessage, FieldArray} from 'vee-validate';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

const StatsItem = defineAsyncComponent(() => import('./Components/StatsItem.vue'));
const SolrSearchResults = defineAsyncComponent(() => import('./Components/SolrSearchResults.vue'));
const FormFrameworkAjax = defineAsyncComponent(() => import('../../../../ds_form_framework/Resources/Private/Vue/Components/FormFrameworkAjax.vue'));
const AjaxForm = defineAsyncComponent(() => import('@ds_forms/Vue/Form/AjaxForm.vue'));
const StepForm = defineAsyncComponent(() => import('@ds_forms/Vue/Form/StepForm.vue'));
const MultiSelect = defineAsyncComponent(() => import('@ds_forms/Vue/Form/MultiSelect.vue'));

const ObservershipGrant = defineAsyncComponent(() => import('@ds_forms/Vue/Grants/ObservershipGrantForm.vue'));
const ResearchGrant = defineAsyncComponent(() => import('@ds_forms/Vue/Grants/ResearchGrantForm.vue'));
const MichaelOgonGrant = defineAsyncComponent(() => import('@ds_forms/Vue/Grants/MichaelOgonGrantForm.vue'));
const BasicDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/BasicDiplomaForm.vue'));
const AdvancedDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/AdvancedDiplomaForm.vue'));
const EdiscDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/EdiscDiplomaForm.vue'));
const EquivalenceBasicDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/EquivalenceBasicDiplomaForm.vue'));
const EquivalenceAdvancedDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/EquivalenceAdvancedDiplomaForm.vue'));
const EquivalenceMultiSourcedDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/EquivalenceMultiSourcedDiplomaForm.vue'));
const FellowshipValidationDiploma = defineAsyncComponent(() => import('@ds_forms/Vue/Diplomas/FellowshipValidationDiplomaForm.vue'));

const AvatarUpload = defineAsyncComponent(() => import('@ds_member_area/Vue/Form/AvatarUpload.vue'));
const AccountDetailsForm = defineAsyncComponent(() => import('@ds_member_area/Vue/Form/AccountDetailsForm.vue'));
const EventRegistrationForm = defineAsyncComponent(() => import('@ds_member_area/Vue/Form/EventRegistrationForm.vue'));
const MembershipApplicationForm = defineAsyncComponent(() => import('@ds_member_area/Vue/Form/MembershipApplicationForm.vue'));
const UserAvatar = defineAsyncComponent(() => import('@ds_member_area/Vue/User/Avatar.vue'));
const UserInvoices = defineAsyncComponent(() => import('@ds_member_area/Vue/User/Invoices.vue'));
const MemberProfile = defineAsyncComponent(() => import('@ds_member_area/Vue/User/MemberProfile.vue'));

const PageHeader = defineAsyncComponent(() => import('./Components/PageHeader.vue'));
const PageFooter = defineAsyncComponent(() => import('./Components/PageFooter.vue'));
const HomepageHeroSlider = defineAsyncComponent(() => import('./Components/HomepageHeroSlider.vue'));

const BarChart = defineAsyncComponent(() => import('./Components/BarChart.vue'));
const CircleChart = defineAsyncComponent(() => import('./Components/CircleChart.vue'));

const NewsList = defineAsyncComponent(() => import('@ds_page_types/Vue/News/List.vue'));
const EventList = defineAsyncComponent(() => import('@ds_page_types/Vue/Event/List.vue'));
const InstitutionalMembersList = defineAsyncComponent(() => import('@ds_page_types/Vue/InstitutionalMembers/List.vue'));

const AnnouncementList = defineAsyncComponent(() => import('./Components/Announcement/List.vue'));
const ReadMore = defineAsyncComponent(() => import('./Components/ReadMore.vue'));
const PastEventNavigationSelectbox = defineAsyncComponent(() => import('./Components/PastEventNavigationSelectbox.vue'));
const EventNavigationDropdown = defineAsyncComponent(() => import('./Components/EventNavigationDropdown.vue'));
const MembershipTable = defineAsyncComponent(() => import('./Components/MembershipTable.vue'));

const CentreList = defineAsyncComponent(() => import('./Components/Centre/List.vue'));
const GlossaryList = defineAsyncComponent(() => import('./Components/Glossary/List.vue'));
const EposterList = defineAsyncComponent(() => import('./Components/Eposter/List.vue'));
const Tabs = defineAsyncComponent(() => import('./Components/Tabs.vue'));
const Accordion = defineAsyncComponent(() => import('./Components/Accordion.vue'));
const Sorting = defineAsyncComponent(() => import('./Components/Sorting.vue'));
const ImageGallery = defineAsyncComponent(() => import('./Components/ImageGallery/List.vue'));
const MembersDirectoryList = defineAsyncComponent(() => import('./Components/MembersDirectory/List.vue'));

const PREVENT_UNLOAD_CLASSES = [
  '.ajax',
  '.download',
  '#scroll-to-top',
  '[data-photoswipe]',
  '[download]',
  '[href^=\\#]',
  '[href*=ajax]',
  '[href^=javascript]',
  '[href^=mailto]',
  '[href^=tel]',
  '[href*=tx_typoscriptrendering]',
  '[href$=".docx"]',
  '[href$=".DOCX"]',
  '[href$=".doc"]',
  '[href$=".DOC"]',
  '[href$=".pdf"]',
  '[href$=".PDF"]',
  '[target^=_blank]',
];

const projectRootComponent: Component = {
  components: {
    'solr-search-results': SolrSearchResults,
    'form-framework-ajax': FormFrameworkAjax,
    'news-list': NewsList,
    'event-list': EventList,
    'page-header': PageHeader,
    'page-footer': PageFooter,
    'homepage-hero-slider': HomepageHeroSlider,
    StatsItem,
    BarChart,
    CircleChart,
    AnnouncementList,
    ReadMore,
    'past-event-navigation-selectbox': PastEventNavigationSelectbox,
    'event-navigation-dropdown': EventNavigationDropdown,
    CentreList,
    GlossaryList,
    InstitutionalMembersList,
    MembershipTable,
    EposterList,
    MembersDirectoryList,
    Tabs,
    'accordion': Accordion,
    Sorting,
    'image-gallery': ImageGallery,
    'ajax-form': AjaxForm,
    ElSelect,
    ElOption,
    ElDatePicker,
    Form,
    Field,
    ErrorMessage,
    FieldArray,
    MultiSelect,

    StepForm,
    ObservershipGrant,
    ResearchGrant,
    MichaelOgonGrant,

    BasicDiploma,
    AdvancedDiploma,
    EdiscDiploma,
    EquivalenceBasicDiploma,
    EquivalenceAdvancedDiploma,
    EquivalenceMultiSourcedDiploma,
    FellowshipValidationDiploma,

    AvatarUpload,
    AccountDetailsForm,
    EventRegistrationForm,
    MemberProfile,
    MembershipApplicationForm,
    UserAvatar,
    UserInvoices
  },

  setup() {
    const header = ref<HTMLElement | null>(null);
    const lazyLoad = new LazyLoad({
      threshold: 0,
      elements_selector: '[lazy]',
      class_loading: 'lazy-loading',
      class_loaded: 'lazy-loaded',
      class_applied: 'lazy-bg-loaded',
      class_error: 'lazy-error',
    });
    const photoSwipe = inject('photoSwipe');
    const cookies:any = inject('cookies');

    const acceptCookies =  (e: Event) => {
      cookies.accept();
      const btnParent = (<HTMLElement>e.target).parentElement;
      const cookieNotice = btnParent?.querySelector(".info-panel-notice");
      cookieNotice?.classList.add("show");
      setTimeout(() => {
        cookieNotice?.classList.remove("show");
      }, 5000)
    }

    watch(cookies.accepted, (value) => {
      if (value) {
        if (window.gtag && typeof window.gtag === 'function') {
          window.gtag('consent', 'update', {
            'ad_storage': cookies.categories.value['socialmedia'] ? 'granted' : 'denied',
            'ad_user_data': cookies.categories.value['socialmedia'] ? 'granted' : 'denied',
            'ad_personalization': cookies.categories.value['analytical'] ? 'granted' : 'denied',
            'analytics_storage': cookies.categories.value['functional'] ? 'granted' : 'denied',
            'functionality_storage': cookies.categories.value['analytical'] ? 'granted' : 'denied',
            'personalization_storage': cookies.categories.value['analytical'] ? 'granted' : 'denied',
            'security_storage': cookies.categories.value['analytical'] ? 'granted' : 'denied'
          });

          window.dataLayer.push({ 'event': 'consentUpdated' });
        }
      }
    }, { immediate: true });

    return {
      header,
      lazyLoad,
      photoSwipe,
      acceptCookies
    };
  },

  methods: {
    initUnload() {
      let links = 'a';

      PREVENT_UNLOAD_CLASSES.forEach((className) => {
        links += `:not(${className})`;
      });
      document.querySelectorAll<HTMLAnchorElement>(links).forEach((link) => {
        link.addEventListener('click', (event) => {
          const target = event.currentTarget as HTMLAnchorElement | null;

          if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
            return true;
          } else if (target?.pathname === window.location.pathname) {
            return true;
          } else if (target?.getAttribute('id') === 'history-back') {
            event.preventDefault();
            if (window.history.length > 1) {
              window.history.back();
            }

            return false;
          }
          document.body.classList.remove('loaded');

          return true;
        });
      });
    }
  }
};

// @ts-ignore
const app = createApp(projectRootComponent);

Sentry.init({
  allowUrls: [window.location.origin],
  app,
  dsn: "https://e390cf5852cf450891fb986fb7e46ae2@sentry.devsk.io/178",
  integrations: [
    new BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

app.use(VueDsCookies, {
  categories: ['functional', 'analytical', 'socialmedia']
});

app.use(DsPhotoSwipe);
app.use(PrimeVue);

app.mount('#page');
